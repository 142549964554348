<template>
	<div class="supplier-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
		<table-summary title="Supplier" filter="name" sort="name"
		               v-bind:order="1"
		               v-bind:fields="fields"
		               v-bind:data-array="suppliers"
		               v-on:add-new="addSupplier"
		               v-on:row-clicked="navigate($event)">
        </table-summary>
	</div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";

export default {
	name: "SupplierTable",
	
	components: {
		TableSummary,
	},
	
	data() {
		return {
			fields: [
				{ display: "Supplier No.", key: "supplierNo", classBinding: ["f-2"] },
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "Type", key: "type", classBinding: [] },
				{ display: "Credit Limit", key: "creditLimit", classBinding: [] },
				{ display: "Contact", key: "contacts", classBinding: ["f-2"] },
				{ display: "Phone", key: "phone", classBinding: [] },
				{ display: "Email", key: "email", classBinding: ["f-2"] },
				{ display: "Handler", key: "handler", classBinding: ["f-2"] },
				{ display: "Address", key: "address", classBinding: ["f-5"] },
			],
		}
	},
	
	computed: {
		suppliers() {
			return this.$store.state.contents.suppliers.map(supplier => {
				const obj = {...supplier};
				const handler = this.$store.state.contents.staffs.find(({ _id }) => _id === supplier.handler);
				obj.handler = handler ? `${handler.preferredName} ${handler.lastName}` : "N/A";
				if (supplier.contacts.length > 0) {
					obj.contacts = supplier.contacts[0].name ? supplier.contacts[0].name : "N/A";
					obj.phone = supplier.contacts[0].phone ? supplier.contacts[0].phone : "N/A";
					obj.email = supplier.contacts[0].email ? supplier.contacts[0].email : "N/A";
				}
				return obj;
			});
		},
	},
	
	methods: {
		async addSupplier() {
			await this.$router.push("/supplier/add");
		},
		
		async navigate(dataObj) {
			await this.$router.push(`/supplier/${dataObj._id}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
